<template>
  <v-dialog :value="value" @input="close" max-width="600px">
    <v-card>
      <v-card-title>
        <v-row justify="start" align="center" no-gutters>
          <div><v-icon>fas fa-lock</v-icon></div>
          <div class="headline ml-1">{{$t('accounts.changePassword.title')}}</div>
        </v-row>

      </v-card-title>
      <v-card-text>

        <v-row no-gutters>
          <v-col cols="12" class="mt-6">
            <v-form ref="form">

              <!-- New password -->
              <v-text-field
                v-model="form.password"
                :label="$t('accounts.changePassword.labels.newPassword')"
                :type="showPassword ? 'text' : 'password'"
                :append-icon="showPassword ? 'fas fa-eye-slash' : 'fas fa-eye'"
                @click:append="showPassword = !showPassword"
                outlined>
                <v-tooltip
                  slot="append-outer"
                  bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on">fas fa-question-circle</v-icon>
                  </template>
                  <template v-for="(policy) in $t('accounts.changePassword.tooltips.password_rules')">
                    <div :key="policy"> - {{policy}}</div>
                  </template>
                </v-tooltip>
              </v-text-field>

              <!-- New password confirmation -->
              <v-text-field
                v-model="form.passwordConfirmation"
                :label="$t('accounts.changePassword.labels.newPasswordConfirmation')"
                type="password"
                outlined>
              </v-text-field>

              <v-btn color="primary" outlined style="padding:0;" @click="generatePassword">
                <v-icon color="primary" class="ml-2">far fa-shield-check</v-icon>
                <span class="mx-2 mt-1">{{$t('accounts.changePassword.buttons.generateRandomPassword')}}</span>
              </v-btn>

            </v-form>

          </v-col>

          <v-col cols="12">
            <AlertBar />
          </v-col>

          <v-col cols="12">
            <ButtonBarFormDialog
              class="mt-5"
              :isLoadingBtnSave="isLoadingBtn.save"
              v-on:cancel="close"
              v-on:submit="customSubmit"/>
          </v-col>

        </v-row>
      </v-card-text>

    </v-card>
  </v-dialog>

</template>

<script>
  import generator from 'generate-password'

  // Mixins
  import dialog from "@/mixins/dialog"

  export default {
    name: "AccountChangePasswordDialog",

    props: {
      userId: {type: String, default: null}
    },

    components: {
      ButtonBarFormDialog: () => import("@/components/Common/Buttons/ButtonBarFormDialog"),
      AlertBar: () => import("@/components/Common/Bars/AlertBar")
    },

    mixins: [dialog],

    data() {
      return {

        showPassword: false,

        form: {
          password: "",
          passwordConfirmation: ""
        },

        isLoadingBtn: {
          save: false
        }
      }
    },

    methods: {
      generatePassword() {
        const generatedPassword = generator.generate(
        {
          length: 10,
          numbers: true,
          lowercase: true,
          uppercase: true
        });

        this.form = {
          password: generatedPassword,
          passwordConfirmation: generatedPassword
        }

        this.showPassword = true;
      },

      customSubmit() {
        if (this.form.password === this.form.passwordConfirmation) {
          this.isLoadingBtn.save = true;
          this.$http.put(`/users/${this.userId}/password`, this.form, {
            headers: {
              Authorization: "Bearer " + this.$session.get('jwt')
            }
          })
          .then(res => {
            this.close(true);
          })
          .catch(err => {
            if (err && err.response && err.response.status === 400)
              this.$store.commit('alert/showError', this.$t('accounts.changePassword.errors')[err.response.data.type]);
            else
              this.$store.commit('alert/showError', this.$t('common.errors.500'));
          })
          .finally(() => {
            this.isLoadingBtn.save = false;
          });
        }
        else
          this.$store.commit("alert/showError", this.$t('accounts.changePassword.errors.WRONG_CONFIRMATION'));
      }
    }
  }
</script>

<style scoped>

</style>